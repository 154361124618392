<template>
    <div class="SysInEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            label-width="100px"
            label-position="right"
            :model="form"
            ref="editForm"
            size="small"
            :rules="rules"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <div>
                    <el-form-item label="机构名称">
                        <el-input v-model="form.deptName" readonly />
                    </el-form-item>
                </div>
            </el-card>
            <el-card shadow="never">
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存</el-button
                >
            </el-card>
            <el-card shadow="never" style="margin-top: 8px">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="440"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="180" fixed="left">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCodes).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="条码" width="130">
                        <template slot-scope="scope">
                            <div v-for="b in skuByGoodsCode(scope.row.goodsCodes).bars" :key="b">{{ b }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCodes).specs }}
                        </template>
                    </el-table-column>
                    <el-table-column label="类目" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCodes).category }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="80">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCodes).unit }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌" width="100">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCodes).brand }}
                        </template>
                    </el-table-column>
                    <el-table-column label="数量" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'detailParams.' + scope.$index + '.counts'" :rules="rules.counts">
                                <ef-stock-biz-count-input
                                    v-model="scope.row.counts"
                                    :sku-type="skuByGoodsCode(scope.row.goodsCodes).type"
                                />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="批发价" width="140">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showWholeSalePrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showWholeSalePrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="零售价" width="140">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showRetailPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showRetailPrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click.native.prevent="handleDelete(scope.$index)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
    </div>
</template>
<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';
import EfPriceInput from 'components/EfPriceInput';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import { deptCodeValidateRule, goodsCodeValidateRule, pricesValidateRule } from 'js/validate/ValidateCommonRule';
import { countValidateRule } from 'js/validate/ValidateStockBizRule';
import GoodsCommon from 'js/GoodsCommon';
import EfStockBizCountInput from 'components/EfStockBizCountInput';

export default {
    name: 'SysInEdit',
    components: { EfPriceInput, EfStockBizCountInput },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            form: {
                deptCode: '',
                deptName: '',
                delCodes: [],
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            detailParamPropertyArrForShow: [
                'codes',
                'goodsCodes',
                'counts',
                new PropertyConverter('wholeSalePrices', 'showWholeSalePrices', (wholeSalePrices) => {
                    return MoneyUtils.moneyToYuan(wholeSalePrices);
                }),
                new PropertyConverter('retailPrices', 'showRetailPrices', (retailPrices) => {
                    return MoneyUtils.moneyToYuan(retailPrices);
                }),
            ],
            rules: {
                deptCode: deptCodeValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showPrices: pricesValidateRule(),
                counts: [
                    countValidateRule(
                        () => this.form.detailParams || [],
                        (goodsCode) => this.skuByGoodsCode(goodsCode)
                    ),
                ],
            },
        };
    },
    mounted() {
        UrlUtils.QueryRemote(this, `/purchase/sysIn/info_c/${this.code}`, async (data) => {
            Util.copyProperties(data, this.form);
            const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(data, this.detailParamPropertyArrForShow);
            this.goodsDetailMap = arr.goodsDetails;
            this.form.detailParams = arr.detailParams;
        });
    },
    computed: {
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
    },
    methods: {
        handleDelete(index) {
            const detailCode = this.form.detailParams.splice(index, 1)[0].codes;
            if (Util.isEmpty(detailCode)) {
                //不存在，说明是单据详情中新增的商品被删除，则不需要处理
                return;
            }
            this.form.delCodes.push(detailCode);
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        handleSave() {
            this.$refs.editForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                const params = {
                    deptCode: this.form.deptCode,
                    delCodes: this.form.delCodes,
                    codes: this.form.detailParams.map((r) => r.codes || null),
                    counts: this.form.detailParams.map((r) => r.counts),
                    goodsCodes: this.form.detailParams.map((r) => r.goodsCodes),
                    wholeSalePrices: this.form.detailParams.map((r) => MoneyUtils.moneyToDb(r.showWholeSalePrices)),
                    retailPrices: this.form.detailParams.map((r) => MoneyUtils.moneyToDb(r.showRetailPrices)),
                };
                UrlUtils.PatchRemote(
                    this,
                    `/purchase/sysIn/patchExtend/${this.code}`,
                    params,
                    null,
                    () => {
                        this.goBackAndReload();
                        this.$message.success('保存成功');
                    },
                    (rst) => {
                        this.$message.error(rst);
                    }
                );
            });
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(money) {
            return MoneyUtils.formatMoney(money);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
